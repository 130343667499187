import React, { useState } from "react";
import { useGlobalContext } from "../Context";

const EditFont = () => {
  const { Color, Fontsize, Fontweight, selectedText, phrases } =
    useGlobalContext();

  return (
    <div className="font-edit">
      {phrases[selectedText] ? (
        <p className="textIndex">Text No: {selectedText + 1} </p>
      ) : null}
      <div className="edit-options">
        <input
          className="edit"
          name="fontEdit"
          type="text"
          size="4"
          value={phrases[selectedText] ? phrases[selectedText].fontSize : 28}
          onChange={(e) => Fontsize(e.target.value)}
          placeholder="Font Size"
        />
      </div>
      <div className="edit-options">
        <input
          className="edit"
          type="color"
          name="Color"
          id="TextColour"
          value={
            phrases[selectedText] ? phrases[selectedText].color : "#000000"
          }
          onChange={(e) => Color(e.target.value)}
        ></input>
      </div>
      <div className="edit-options">
        <select
          className="edit"
          name="fontWeight"
          onChange={(e) => Fontweight(e.target.value)}
          value={
            phrases[selectedText] ? phrases[selectedText].fontWeight : "500"
          }
          placeholder="Font Weight"
        >
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="400">400</option>
          <option value="500">500</option>
          <option value="600">600</option>
          <option value="700">700</option>
          <option value="800">800</option>
          <option value="900">900</option>
        </select>
      </div>
    </div>
  );
};

export default EditFont;
