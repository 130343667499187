import React from "react";
import Loader from "./Loader";

const Pagination = ({ pageNumber, pages, setPageNumber, isFetching }) => {
  return (
    <>
      <div className="btn-container">
        {pages.map((pageIndex, index) => (
          <button
            className={`page-btn ${index === pageNumber ? "active-btn" : null}`}
            key={index}
            onClick={() => setPageNumber(pageIndex)}
          >
            <span>{pageIndex + 1}</span>
          </button>
        ))}
      </div>
      {isFetching ? (
        <div className="pageLoading">
          <Loader />
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
