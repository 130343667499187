import React from "react";
import { categories } from "../categories";

const Category = ({ cat, handleCatSelect }) => {
  return (
    <>
      <div className="dropdown">
        <label htmlFor="categories">Select Category</label>
        <select
          name="categories"
          id="categories"
          onChange={handleCatSelect}
          value={cat}
        >
          {categories.map((category, index) => {
            const { value, name } = category;
            return (
              <option key={index} value={value}>
                {name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default Category;
