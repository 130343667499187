import React, { useEffect } from "react";
import Inputs from "./Inputs";
import MemeContainer from "./MemeContainer";
import Upload from "./Upload";
import { useGlobalContext } from "../Context";

const OwnTemplate = () => {
  const { setPhrases } = useGlobalContext();
  const reset = () => {
    setPhrases([]);
  };

  useEffect(() => {
    reset();
  }, []);
  return (
    <div className="meme-page">
      <Inputs />
      <MemeContainer />
      {/* <Upload /> */}
    </div>
  );
};

export default OwnTemplate;
