import React, { useState, useContext, createContext } from "react";
import html2canvas from "html2canvas";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [lang, setLang] = useState("hi");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const [phrases, setPhrases] = useState([]);
  const [selectedText, setSelectedText] = useState(0);
  const [selectedImage, setSelectedImage] = useState({});

  const Language = (e) => {
    setLang(e.target.value);
  };

  const Text = (text) => {
    setText(text);
  };

  const Color = (color) => {
    setPhrases(
      phrases.map((text, i) => {
        if (i === selectedText) {
          return { ...text, color };
        }
        return text;
      })
    );
  };

  const Fontsize = (fontSize) => {
    setPhrases(
      phrases.map((text, i) => {
        if (i === selectedText) {
          return { ...text, fontSize };
        }
        return text;
      })
    );
  };

  const Fontweight = (fontWeight) => {
    setPhrases(
      phrases.map((text, i) => {
        if (i === selectedText) {
          return { ...text, fontWeight };
        }
        return text;
      })
    );
  };

  function uploadImage(event) {
    setImage(window.URL.createObjectURL(event.target.files[0]));
  }

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleTextClick = (index) => {
    setSelectedText(index);
  };

  function takeScreenshot() {
    html2canvas(document.getElementById("meme-container"), {
      allowTaint: true,
      useCORS: true,
      scrollY: 0,
    }).then((canvas) => {
      const image = canvas.toDataURL();
      const link = document.createElement("a");
      link.href = image;
      link.download = "meme.png";
      link.click();
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const phrase = { text };
    setPhrases([
      ...phrases,
      { newText: text, color: "#000000", fontSize: 28, fontWeight: "500" },
    ]);
    setText("");
  };

  return (
    <AppContext.Provider
      value={{
        lang,
        text,
        image,
        phrases,
        selectedImage,
        selectedText,
        Language,
        Text,
        Color,
        Fontsize,
        Fontweight,
        setImage,
        uploadImage,
        takeScreenshot,
        handleSubmit,
        setPhrases,
        handleImageClick,
        handleTextClick,
        // handleColorChange,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

//custom hook:

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
