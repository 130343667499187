import "./App.css";
import { Routes, Route } from "react-router-dom";
import MemeImg from "./components/MemeImg";
import OwnTemplate from "./components/OwnTemplate";
import LandingPage from "./components/LandingPage";
import Header from "./components/Header";
import FetchTemplates from "./components/FetchTemplates";
import { QueryClient, QueryClientProvider } from "react-query";
import Footer from "./components/Footer";
import Layout from "./components/Layout";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="appjs">
      <Layout>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/select-meme-template" element={<FetchTemplates />} />
            <Route path="/template/:pinId" element={<MemeImg />} />
            <Route path="/use-own-template" element={<OwnTemplate />} />
          </Routes>
        </QueryClientProvider>
      </Layout>
    </div>
  );
}

export default App;
