import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Inputs from "./Inputs";
import { useGlobalContext } from "../Context";
import Draggable from "react-draggable";
import Phrases from "./Phrases";
import Loader from "./Loader";
import MemeContainer from "./MemeContainer";

const MemeImg = () => {
  const { selectedImage, setPhrases } = useGlobalContext();
  const { fontSize, color, fontWeight, phrases, image } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setPhrases([]);
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <div className="meme-page">
      {loading ? (
        <div className="pageLoading">
          <Loader />
        </div>
      ) : null}
      <Inputs selectedImage={selectedImage} />
      <MemeContainer selectedImage={selectedImage} />
    </div>
  );
};

export default MemeImg;
