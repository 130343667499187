import React, { useState } from "react";
import Loader from "./Loader";
import Masonry from "react-masonry-css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Category from "./Category";
import Pagination from "./Pagination";
import { useGlobalContext } from "../Context";

const Query = () => {
  const navigate = useNavigate();
  const [cat, setCat] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const { handleImageClick } = useGlobalContext();

  const handleCatSelect = (e) => {
    setCat(e.target.value);
    setPageNumber(0);
  };

  const fetchImages = (pageNumber, cat) =>
    fetch(
      `https://mememama-ndzk.onrender.com/select-meme-template/?page=${pageNumber}${
        cat !== "" ? `&category=${cat}` : ""
      }`
    ).then((response) => response.json());

  const { data, isLoading, isFetching } = useQuery(
    ["images", pageNumber, cat],
    () => fetchImages(pageNumber, cat),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );

  const numberOfPages = data?.totalPages;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (isLoading)
    return (
      <div className="pageLoading">
        <Loader />
      </div>
    );

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    800: 1,
  };

  let thumbnails = data?.Images.map((img) => {
    return (
      <div
        key={img._id}
        className="img-div"
        onClick={() => navigate(`/template/${img._id}`)}
      >
        <img
          className="fetchedImages"
          src={`data:image/${img.contentType};base64, ${img.imageBase64}`}
          alt="meme-template"
          onClick={() => handleImageClick(img)}
          loading="lazy"
        />
      </div>
    );
  });

  return (
    <div>
      <Category handleCatSelect={handleCatSelect} cat={cat} />
      <div className="itemsDiv">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {thumbnails}
        </Masonry>
      </div>
      <Pagination
        pageNumber={pageNumber}
        pages={pages}
        setPageNumber={setPageNumber}
        isFetching={isFetching}
      />
    </div>
  );
};

export default Query;
