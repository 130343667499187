export const categories = [
  {
    name: "All",
    value: "",
  },
  {
    name: "English",
    value: "English",
  },
  {
    name: "Hindi",
    value: "Hindi",
  },
  {
    name: "Telugu",
    value: "Telugu",
  },
  {
    name: "Cartoon",
    value: "Cartoon",
  },
];
