import React from "react";
import Meme from "./images/meme.webp";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="landingPage">
      <div className="landingPgMeme">
        <img className="lpMemePic" src={Meme} alt="meme" />
      </div>
      <div className="landingPgBtn">
        <Link to="/use-own-template" className="lpBtn">
          Use own template
        </Link>
        <Link to="/select-meme-template" className="lpBtn">
          Select template
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
