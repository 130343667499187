import React from "react";
import Draggable from "react-draggable";
import { useGlobalContext } from "../Context";

const Phrases = () => {
  const { phrases, fontSize, color, fontWeight, handleTextClick } =
    useGlobalContext();

  return (
    <div>
      {phrases.map((phrase, index) => {
        return (
          <div
            className="text-container"
            key={index}
            onClick={() => handleTextClick(index)}
          >
            <Draggable>
              <p
                style={{
                  fontSize: `${phrase.fontSize}px`,
                  fontWeight: phrase.fontWeight,
                  color: phrase.color,
                }}
              >
                {phrase.newText}
              </p>
            </Draggable>
          </div>
        );
      })}
    </div>
  );
};

export default Phrases;
