export const languages = [
  { label: "Hindi", value: "hi" },
  { label: "Telugu", value: "te" },
  { label: "Tamil", value: "ta" },
  { label: "Kannada", value: "kn" },
  { label: "Malayalam", value: "ml" },
  { label: "Bangla", value: "bn" },
  // { label: "Punjabi", value: "pa" },
  // { label: "Gujarati", value: "gu" },
  // { label: "Marathi", value: "mr" },
  { label: "Odia", value: "or" },
  { label: "English", value: "en" },
  // { label: "Belarusian", value: "be" },
  // { label: "Bulgarian", value: "bg" },
  // { label: "French", value: "fr" },
  // { label: "German", value: "de" },
  // { label: "Greek", value: "el" },
  // { label: "Italian", value: "it" },
  // { label: "Portuguese (Brazil)", value: "pt" },
  // { label: "Spanish", value: "es" },
  // { label: "Japanese", value: "ja" },
  // { label: "Russian", value: "ru" },
  // { label: "Serbian", value: "sr" },
  // { label: "Ukrainian", value: "uk" },
  // { label: "Vietnamese", value: "vi" },
];
