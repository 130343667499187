import React from "react";
import Logo from "./images/Meme-logo-new3.webp";

const Header = () => {
  return (
    <header className="headerComp">
      <img src={Logo} alt="logo" height={90} width={90} />
      <p>MemeMama</p>
    </header>
  );
};

export default Header;
