import React from "react";

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <footer>
      <h5>
        &copy; MemeMama {year} - The ONLY meme generator you'll ever need!
      </h5>
    </footer>
  );
};

export default Footer;
