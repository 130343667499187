import React from "react";
import { useGlobalContext } from "../Context";
import { languages } from "../Languages";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import EditFont from "./EditFont";

const Inputs = ({ selectedImage }) => {
  const {
    uploadImage,
    takeScreenshot,
    lang,
    Language,
    text,
    Text,
    handleSubmit,
    setPhrases,
    setImage,
  } = useGlobalContext();

  const reset = () => {
    setPhrases([]);
    setImage("");
  };

  return (
    <div className="Inputs">
      <form onSubmit={handleSubmit}>
        {!selectedImage && (
          <div className="upload-btn">
            <label htmlFor="upload-image">
              Upload Image
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={uploadImage}
                id="upload-image"
              />
            </label>
          </div>
        )}
        <select
          className="languages"
          value={lang}
          onChange={Language}
          name="selectLanguage"
        >
          {languages.map((language, index) => {
            return (
              <option value={language.value} key={index}>
                {language.label}
              </option>
            );
          })}
        </select>
        <ReactTransliterate
          id="selectlang"
          value={text}
          onChangeText={Text}
          lang={lang}
          placeholder="Your text"
        />
        <div>
          <button className="btn">Add Text</button>
        </div>
        <EditFont />
      </form>
      <button className="btn" onClick={takeScreenshot}>
        Download Meme
      </button>
      <button className="btn" onClick={reset}>
        Reset
      </button>
    </div>
  );
};

export default Inputs;
