import React from "react";
import { useGlobalContext } from "../Context";
import Phrases from "./Phrases";

const MemeContainer = ({ selectedImage }) => {
  const { image } = useGlobalContext();

  return (
    <>
      {selectedImage ? (
        <div id="meme-container">
          {
            <img
              className="imageBox"
              src={`data:image/${selectedImage.contentType};base64, ${selectedImage.imageBase64}`}
              alt=""
              //width={450}
            />
          }
          <Phrases />
        </div>
      ) : (
        <div id="meme-container">
          <img className="imageBox" src={image} alt="" width={450} />
          <Phrases />
        </div>
      )}
    </>
  );
};

export default MemeContainer;
